// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	position: relative;

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		padding: 20px 20px 10px 20px;
		
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: 0;
		a { word-wrap: break-word; }
		.card-summary-wrapper,
		.card-authors {
			padding: 0 20px 20px 20px;
			font-size:1.222rem;
		}
	}
	&:after {
		display:inline-block;
		height: 10px;
		width:100%;
		content:"";
		position: absolute;
		bottom:0;
	}
	.card-date,
	.card-location,
	.card-job-title {
		padding: 0 20px 30px 20px;
	}
}

.node-resource .card .card-date {
	padding:20px 20px 0;
}

// PALETTE ALT SPECIFIC BULLSHIT
.palette-alt-3 {
	.view-links,
	.view-links-flexi {
		a.card,
		.card {
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color: $card-dark-title-color!important;
				background:$card-dark-title-bg;
			}
			&:hover {
				background: $clear_vision;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					background-color:$card-hover-bg-color-alt;
					color: $card-dark-title-color!important;
				}
			}
			h3 {
				position: relative;
				padding:20px 20px 30px 20px;
				&:after {
					display:inline-block;
					height: 10px;
					width:100%;
					content:"";
					position: absolute;
					bottom:0;
					left:0;
				}
			}
			&:after {
				display:none;
			}
			.card-text .card-summary-wrapper,
			.card-job-title {
	    	padding: 10px 20px 20px 20px;
			}
		}
	}
}

.card-tweet-message {
  padding: 20px;
}

.card-display-date {
	color:$card-date-text-color;
	padding: 0 20px 15px 20px;
	font:$card-date-font;
}
.card-event-date {
	.card-event-date-wrapper {
		float: left;
		width: 100%;
		padding:20px;
		.card-event-day-wrapper,
		.card-event-month-year-wrapper {
			float:left
		}
		.card-event-day-wrapper {
			font: 600 2.1111rem/1 $title-font-family;
			color:$primary;
		}
		.card-event-month-year-wrapper {
			font: 0.7777rem/1.3 $base-font-family;
			padding-left: 3px;
			color:$primary;
		}
	}
}


// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding: 20px 40px 20px 20px;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:23px;
				margin:-4px 0 -2px 10px;
				width:14px;
				bottom:auto;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}
.section-row:not(.view-type-slider){
	.card-more-link a.card:before {
		display:inline-block;
		height: 10px;
		width:100%;
		content:"";
		position: absolute;
		bottom:0;
		left:0;
	}
	&.palette-default {
		.card-more-link a.card:before {
			background:$card-medium-border-color;
		}
	}
	&.palette-alt-1 {
		.card-more-link a.card:before {
			background:$card-light-border-color;
		}
	}
	&.palette-alt-2 {
		.card-more-link a.card:before {
			background:$card-light-border-color;
		}
	}
	&.palette-highlight {
		.card-more-link a.card:before {
			background:$card-highlight-border-color;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: visible;
	position: relative;
	height:50px;
	margin-right:20px;
	
	a {
		padding:1px 40px 2px 15px;
		border-radius: 30px;
		&:after {
			content: "";
			display: inline-block;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			position: absolute;
			z-index: 10;
			right: -11px;
			bottom: 15px;
		}
		&:before {
			content: "";
			display: inline-block;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			background:white;
			position: absolute;
    	right: -15px;
    	z-index: 0;
  	  bottom: 11px;
		}
	}
}

.palette-default,
.palette-alt-3 {
	.card-feed-link a {
		&:after {
			background:white;
		}
		&:before {
		background: white;
		border: 4px solid white;
		}
	}
}
.palette-highlight .card-feed-link a {
	&:after {
		background:$musk_melon;
	}
	&:before {
	background: $musk_melon;
	border: 4px solid $musk_melon;
	}
}
.palette-alt-1,
.side-row {
	.card-feed-link a {
		&:after {
			background:$frosty_mint;
		}
		&:before {
		background: $frosty_mint;
		border: 4px solid $frosty_mint;
		}
	}
}
.palette-alt-2 .card-feed-link a {
	&:after {
		background:$clear_vision;
	}
	&:before {
	background: $clear_vision;
	border: 4px solid $clear_vision;
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-date,
.card-location {
	font-size:0.7778em;
	line-height:1.57142857142857;
	color:$dark_silver;
}
.card-type {
	font-size:0.875rem;
	line-height:1.57142857142857;
}
.card-label {
	font-weight:bold;
}
.card-date-display-override,
.node-rss-feed .card-display-date {
    font-size: 0.777em;
    padding: 20px 20px 0;
		color:$dark_silver;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
}
.card-email {
	font-weight: 600;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
	color: $dark_silver;
}

.card-footer {
	display: block;
	line-height: 1.125em;
	padding:0 20px 20px 20px;
	.card-published-by {
    margin-bottom: 10px;
		font-size: 0.777em;
		color:$dark_silver;
	}
	.card-resource-type {
		color:$dark_silver;
		font-size: 0.777em;
	}
}

.node-rss-feed .card-footer .card-summary {
	margin-bottom: 10px;
	font-size: 0.777em;
	color:$dark_silver;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary,
		.card-authors {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}

.card-role {
    padding: 0 20px 30px;
}

.side-row .card-more-link a.card {
	padding:$vert-space $horz-space;
	text-align: center;
	font:$card-more-link-font;
	&:after {
		content:" ";
		display: inline-block;
		height: 20px;
		margin:0 0 -4px 10px;
		width: 20px;
		top:23px;
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;