$button-bg           : #81D2C7 !default;
$button-text-color   : white !default;
$button-border-color : transparent !default;

@mixin button-states($button-bg, $button-text, $button-border-color) {
	background: $button-bg;
	border-color:$button-border-color;
	color: $button-text;
	display: inline-block;
	transition: all 0.25s ease-in-out;
	
	&:link, &:visited {
		background: $button-bg;
		border-color:$button-border-color;
		color: $button-text;
	}
	
	&:hover {
		background: darken($button-bg,10%);
		border-color: darken($button-border-color,10%);
		color: $button-text;
	}
}


// /* BUTTON LINKS */
@mixin button-link-default() {
	a.button,
	.button a {
		background:$button-link-default-bg;
		border-color: $button-link-default-border-color;
		color: $button-link-default-color;
		&:link, &:visited {
			background:$button-link-default-bg;
			border-color: $button-link-default-border-color;
			color: $button-link-default-color;
		}
		&:after {
			//background: $button-link-default-icon;
			border:solid 4px $button-link-default-border-color;
		}
		&:hover {
			background:$button-link-default-hover-bg;
			border-color: $button-link-default-hover-border-color;
			color: $button-link-default-hover-color;
			&:after {
				background: $button-link-default-hover-icon;
				border-color: $button-link-default-hover-border-color;
			}
		}
	}
}

@mixin button-link-alt() {
	a.button,
	.button a {
		background:$button-link-alt-bg;
		border-color: $button-link-alt-border-color;
		color: $button-link-alt-color;
		&:link, &:visited {
			background:$button-link-alt-bg;
			border-color: $button-link-alt-border-color;
			color: $button-link-alt-color;
		}		
		&:after {
			//background: $button-link-alt-icon;
			border: solid 4px $button-link-alt-border-color;
		}
		&:hover {
			background:$button-link-alt-hover-bg;
			border-color: $button-link-alt-hover-border-color;
			color: $button-link-alt-hover-color;
			&:after {
				background: $button-link-alt-hover-icon;
				border-color: $button-link-alt-hover-border-color;
			}
		}
	}
}
