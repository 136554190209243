main {
	 #sidebar-second {
		background: $sidebar-second-bg;
		margin: $sidebar-second-margin;
		padding: $sidebar-second-padding;
	}

	.side-row {
		background: $side-row-bg;
		//border: $side-row-border;
		//border-radius: $side-row-border-radius;
		//box-shadow: $side-row-box-shadow;
		margin: $side-row-margin;
		padding:$side-row-padding;
		overflow: hidden;
    

		@include palette-sidebar-slick-controls;
		@include palette-sidebar-button-link;

		 &.section-with-slider {
			 padding:0;
		 }

		.section-inner {
			padding: $side-row-inner-padding;
			@media (max-width:$narrow) {
				padding: $side-row-inner-mobile-padding;
			}		
		}

		h2 {
			font:$side-row-title-font;
      text-align: center;
      position: relative;
			.card-title {
				font-size: inherit;
			}
      &:after {
        content:"";
        display: inline-block;
        height: 50px;
        width: 50px;
        background:$ico-circle-sidebar no-repeat 50% 50% / 50px 50px;
        position: absolute;
        top: -20px;
        left: -20px;
      }
		}
		
		.section-inner > .card-title > h2,
		&.block h2 {
//			margin: -20px 0 0 -20px;
//			padding: 20px;
//			width: calc(100% + 40px);
//			margin-bottom: 20px;
//			border-bottom: none;
		}
		
		.view-header .side-row {
//			margin: 0;
//			padding: 0;
//			overflow: visible;
		}

		.card {
			@include palette-sidebar-cards;

			border: $card-sidebar-border;
			border-radius: $card-sidebar-border-radius;
			box-shadow: $card-sidebar-shadow ;
			margin-bottom: $card-sidebar-margin-bottom;

			.card-text {
				//padding: $card-text-padding;
			}
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-sidebar-card-more-link;
		}
		.card-feed-link {
			@include palette-sidebar-card-feed-link;
		}
	}
} 

.side-row {
  .card-label {
    margin-bottom:5px;
  }
  .card-resource-type,
  .card-published-by,
  .card-link,
  .card-source {
    margin-bottom:10px;
    font-size: 1em;
  }
}

//COME BACK TO THIS LATER
.side-row .card-with-image .card-image {
    float: none!important;
}