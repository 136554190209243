section .section-row.section-with-promo {
  padding: 0;
  width: 100%;
  position: relative;
  line-height: 0;
  padding: 30px 0;
  .section-inner {
    max-width: none;
    width: 100%;
    overflow: visible;
  }
}
.view-promo {
  margin:0 -30px 0;
  @media(min-width:720px) {
    margin:75px auto 0; 
    max-width: 1600px;
    padding:0 15px;
  }
  a.card-promo-text:hover {
    background:$dark_denim;
  }
  .promo {
    width: 100%;
    position:relative;
    &:after {
      content:"";
      background:$ico-circle-promo no-repeat 50% 50% / 160px 160px;
      height: 160px;
      width:160px;
      display:inline-block;
      position: absolute;
      top: 50px;
      right:20px;
      z-index: 11;
      mix-blend-mode: multiply;
      @media (min-width:400px) {
        top: calc(30% - 80px);
      }
      @media (min-width:500px) {
        top: calc(40% - 80px);
      }
      @media (min-width:610px) {
        top: calc(50% - 80px);
      }
      @media (min-width:720px) {
        background:$ico-circle-promo no-repeat 50% 50% / 120px 120px;
        height: 120px;
        width:120px;
        top:30px;
        top:-70px;
        left: calc(50% - 80px);
      }
      @media (min-width:1290px) {
        left: 520px;
      }
    } 
    .card-image { 
      z-index: 10;
      position: relative;
      margin-right:100px;
      @media (min-width:610px) {
        max-width:610px;
      }
      @media (min-width:720px) {
        width:50%;
        margin-right:40px;
      }
    }
    picture, img {
      max-width: none;
      width: 100%;
      display: inline-block;
      vertical-align: baseline;
    }
    .card-promo-text { 
      background: $blue_lobelia;
      padding: 40px;
      display: inline-block;
      margin-left:40px;
      width: calc(100% - 125px);
      margin-top:-20px;
      max-width: 610px;
      p:last-child {
        margin-bottom:0;
      }
      @media(min-width:720px) {
        margin: 0;
        padding:60px 40px 60px 80px;
        width:54%;
        position: relative;
        float: right;
        margin-top: -30%;
      }
      @media(min-width:1290px) {
        width:calc(100% - 580px);
        max-width: 100%;
        margin-top: -364px;
      }
      .promo-title {
        color:white;
        font-size: 2em;
        font-weight: bold;
        margin-bottom:20px;
        line-height: 1;
        max-width:350px;
        @media(min-width:540px) {
          max-width:450px;
        }
      }
      .card-description {
        color: white;
        max-width:400px;
        @media(min-width:540px) {
          max-width:450px;
        }
        @media(min-width:1200px) {
          max-width:100%;
        }
        
        h2, h3, h4, h5, h6 {
          color: white;
        }
      }
    }  
  }
}