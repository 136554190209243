.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		color: $dark_silver !important;
		font-weight: 600;
		height:40px;
		width:40px;
		text-indent: -9999px;
		
		&:hover {
			//background:$action-hover;
			color:$musk_melon !important;
		}
	}
	li.pager-next {
		// float: right;
		// position: absolute;
		// right: 55px;
		margin-left: 10px;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			display: block;
			background: $arrow-cerise-right no-repeat 50% 50% / 17px 15px;
			border-radius: 50%;
			border:solid 4px rgba(214, 47, 95, 0.2);
			&:hover {
				display: block;
				background: $arrow-melon-right no-repeat 50% 50% / 17px 15px;
				border-radius: 50%;
				border:4px solid rgba(240, 145, 95, 0.2);
			}
		}
	}
	li.pager-last {
		//float: right;
		margin-left: 5px;
		a {
			display: block;
			background: $arrow-cerise-last no-repeat 50% 50% / 17px 15px;
			border-radius: 50%;
			border:solid 4px rgba(214, 47, 95, 0.2);
			&:hover {
				display: block;
				background: $arrow-melon-last no-repeat 50% 50% / 17px 15px;
				border-radius: 50%;
				border:4px solid rgba(240, 145, 95, 0.2);
			}
		}
	}
	li.pager-previous {
		// float: left;
		// position: absolute;
		// left: 55px;
		margin-right: 10px;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			display: block;
			background: $arrow-cerise-left no-repeat 50% 50% / 17px 15px;
			border-radius: 50%;
			border:solid 4px rgba(214, 47, 95, 0.2);
			&:hover {
				display: block;
				background: $arrow-melon-left no-repeat 50% 50% / 17px 15px;
				border-radius: 50%;
				border:4px solid rgba(240, 145, 95, 0.2);
			}
		}
	}
	li.pager-first {
		//float: left;
		margin-right: 5px;
		a {
			display: block;
			background: $arrow-cerise-first no-repeat 50% 50% / 17px 15px;
			border-radius: 50%;
			border:solid 4px rgba(214, 47, 95, 0.2);
			&:hover {
				display: block;
				background: $arrow-melon-first no-repeat 50% 50% / 17px 15px;
				border-radius: 50%;
				border:4px solid rgba(240, 145, 95, 0.2);
			}
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0 3px;
	}
	li.pager-current {
		//background: $action-default;
		color:$cerise_red;
		padding:0.25em 0.5em;
		margin-top: 0.25em;
		//border-radius:50%;
		//border-top: 3px solid;
		//border-bottom: 3px solid;
	}
}

.section-row.palette-highlight {
	.item-list  ul.pager {
		a {
			color: $black_ink  !important;
			
			&:hover {
				color:white!important;
			}
		}
		li.pager-next {
			a {
				background: $arrow-apple-right no-repeat 50% 50% / 17px 15px;
				border:solid 4px rgba(163, 9, 0, 0.2);
				&:hover {
					background: $arrow-white-right no-repeat 50% 50% / 17px 15px;
					border:4px solid rgba(255, 255, 255, 0.2);
				}
			}
		}
		li.pager-last {
			a {
				background: $arrow-cerise-last no-repeat 50% 50% / 17px 15px;
				border:solid 4px rgba(214, 47, 95, 0.2);
				&:hover {
					background: $arrow-white-last no-repeat 50% 50% / 17px 15px;
					border:4px solid rgba(255, 255, 255, 0.2);
				}
			}
		}
		li.pager-previous {
			a {
				background: $arrow-apple-left no-repeat 50% 50% / 17px 15px;
				border:solid 4px rgba(163, 9, 0, 0.2);
				&:hover {
					background: $arrow-white-left no-repeat 50% 50% / 17px 15px;
					border:4px solid rgba(255, 255, 255, 0.2);
				}
			}
		}
		li.pager-first {
			a {
				background: $arrow-cerise-first no-repeat 50% 50% / 17px 15px;
				border:solid 4px rgba(214, 47, 95, 0.2);
				&:hover {
					background: $arrow-white-first no-repeat 50% 50% / 17px 15px;
					border:4px solid rgba(255, 255, 255, 0.2);
				}
			}
		}
	}
	li.pager-current {
		color:$apple_red;
	}
}