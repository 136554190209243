// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#main-navigation {
	// .block-menu-block {
	// 	display: none;
	// }
	.main-nav-wrapper,
	.block-menu-block {
		
		@media (min-width:880px) and (max-width:1150px) {
			min-height:48px;
		}

		@include media(1150px) {
			min-height:48px;
		}
	}
}

// MENU STYLES
#main-navigation {
	@include media(880px) {
		background: $blue_lobelia;
		clear: both;
		width: 100%;
	}
	.block-menu-block {
		clear:right;
		position: relative;
		
		.main-menu-btn {
			//background-color:$nav-button-bg-color;
			float:right;
			width:$nav-button-width;
			height:$nav-button-height;
			text-indent:-999em;
			margin: -($nav-button-height) 0px 0px 0px;
						
			.main-menu-btn-icon {
 				left:16px;
				height:$nav-button-bar-height;
				width:$nav-button-bar-width;
				background-color:$nav-button-bar-bg-color;
				border-radius:$nav-button-bar-border-radius;
				margin-top:-2px;
				
				&:before {
					top:-14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
				
				&:after {
					top:14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
			} 
		}
		
		ul.sm {
			background: none; 
			clear:both;
		}
		
		@include media(880px) { 
			clear:right;
			float:right;
		}		
		
		.menu-block-wrapper ul.menu.sm  {
			
			border:none;
			box-shadow:none;
					
			& > li {
				border-left:none;
				flex-grow: 1;
				text-align: center;

				&:last-child { 
					position:relative;
				}

				@include media(880px) { 
					& > a > .sub-arrow {
						display:none;
					}
				}

				& > a {
					background-color: $nav-base-bg-color;
					text-align: center;
					font:$nav-base-font;
					color:$nav-base-text-color;
					padding:$nav-base-padding;
					text-transform: uppercase;
					height:54px;

					@media (min-width:880px) and (max-width:1150px) {
						font: $nav-base-font-smaller;
						padding: $nav-base-padding-smaller;
					}

					&:hover {
						border-bottom: $nav-hover-border;
						padding:$nav-base-hover-padding;
						background-color:$nav-hover-bg-color;
						color:$nav-hover-text-color!important;
						// &:after {
						// 	display: inline-block;
						// 	height: 20px;
						// 	width: 20px;
						// 	content: "";
						// 	background: $ico-circle-menu-aspen no-repeat 50% 50% / 20px 20px;
						// 	position: absolute;
						// 	left: -10px;
						// 	bottom: calc(50% - 10px);
						// 	z-index:20;
						// 	@media(min-width:880px) {
						// 		bottom: -12px;
						// 		left: calc(50% - 10px);
						// 	}
						// }

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}
					}			
					&.current,
					&.active.current.highlighted {
						background-color:$nav-active-bg-color;
						color:white;
						border-radius:$nav-active-border-radius;
						&:after {
						display: inline-block;
						  height: 20px;
						  width: 20px;
						  content: "";
						 	background: $ico-circle-menu-melon no-repeat 50% 50% / 20px 20px;
						  position: absolute;
							left: -10px;
					    bottom: calc(50% - 10px);
							z-index: 20;
							@media(min-width:880px) {
								bottom: -12px;
						    left: calc(50% - 10px);
							}
						}
						&:hover:after {
							background: $ico-circle-menu-aspen no-repeat 50% 50% / 20px 20px;
						}
					}
					

					&.current.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-radius:$nav-active-border-radius;
					}

					&.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-bottom: $nav-highlight-border;
						border-top: none;
						padding:$nav-base-hover-padding;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}

						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
						}
					}
				}
			}
			
			li {
				a {
					cursor:pointer; 
					//background-color: transparent;
										
					.sub-arrow {
						background:$arrow-black-right no-repeat scroll center center transparent;
						background-size:contain;
						width: 16px;
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}
				
				ul.menu {	
					background:$nav-sub-bg-color;
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					box-shadow:$nav-sub-box-shadow;
					z-index:10;
					
					li {
						border-top:none; 
						
						a {
							color:$nav-sub-text-color;
							font: $nav-sub-base-font;
							line-height:1.225;
							padding:$nav-sub-base-padding;
							
							&.current {
								background:$nav-sub-bg-color;
							}
							&:hover {
								color:$nav-sub-hover-text-color;
							} 
							@media (min-width:880px) {
								&:hover {
									background-color:white;
								} 
							}
							
							&.active {
								//background-color:$nav-sub-hover-bg-color;
								color:$nav-active-text-color;
								&:after {
										display: inline-block;
										height: 8px;
										width: 8px;
										content: "";
										background: $ico-circle-menu-melon no-repeat 50% 50% / 8px 8px;
										position: absolute;
										bottom: calc(50% - 4px);
										left: 5px;
								}
							} 
							
							.sub-arrow {
								background:$arrow-grey-right no-repeat scroll center center transparent;
								background-size:contain;
								text-indent:-999em;
								overflow:hidden;
								right:10px;				
							}	
							
							&.active {
								.sub-arrow {
									background:$arrow-black-right no-repeat scroll center center transparent;
									background-size:contain;
								}
							}
							
							&:hover, 
							&.active:hover  {
								.sub-arrow {
									background:$arrow-cerise-right no-repeat scroll center center transparent;
									background-size:contain;
								}								
							}							
						}						
						&.expanded {
							
							& > a.active-trail {
								background:$frosty_mint;
								&:after {
										display: inline-block;
										height: 8px;
										width: 8px;
										content: "";
										background: $ico-circle-menu-melon no-repeat 50% 50% / 8px 8px;
										position: absolute;
										bottom: calc(50% - 4px);
										left: 5px;
								}
								&:hover:after {
									background: $ico-circle-menu-cerise no-repeat 50% 50% / 8px 8px;
								}
							}
							
							& > a.highlighted { 
								//background-color:$nav-hover-bg-color;
								//color:$nav-hover-text-color;

								// .sub-arrow {
								// 	background:$arrow-grey-right no-repeat scroll center center transparent;
								// 	background-size:contain;
								// }
								
								&.current.active,
								&:hover  {
									color:$nav-sub-hover-text-color;
								}
							}
						}
					}
				}
			}
			
		}	
		
////////////////////////////////////      MOBILE      ////////////////////////////////////
		@media (max-width:879px) {
		
			#main-menu-state:checked ~ .main-menu-btn { background:$nav-button-hover-bg-color;} //margin: 0 0 1px; height: 75px;}
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:$nav-button-bar-height; margin-top: -4px; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }


			.menu-block-wrapper ul.menu.sm {
				
				border: $nav-mob-border;
				border-top: none; 
				box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
				
				position: absolute;
				right: 0;
				width: calc(100% - 5%); 
				max-width: 400px;
				z-index: 1005;
				
				@media (min-width:$narrow) and (max-width:879px) {
					top: 0;
				}
				
				& > li {
					
					&.first.leaf {
						//border: none;
						//border-top:$nav-mob-expanded-border;
					}
					&.first.first {
						border: none;
					}
					
					a .sub-arrow {
						width: 34px;
					}

					///////////////////////////////      1st LEVEL      ///////////////////////////////
					& > a {
						background-color:$nav-mob-1st-bg-color;
						text-align: left;
						font:$nav-base-font;
						color:$nav-sub-text-color;
						padding:$nav-sub-base-padding;
						text-transform: uppercase;

						&:hover {
							background-color:$nav-sub-hover-bg-color;
							color:$aspen_gold;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center / 20px 20px transparent;
							}	
						}

						&.current { 
							background:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-hover-border;
							padding:$nav-sub-base-hover-padding;
							//border-top:$nav-mob-expanded-border;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center /20px 20px transparent;
							}
							&:hover {						
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-gold-down no-repeat scroll center center /  20px 20px transparent;
								}
							}
						}

						&.active { 
							background-color: $nav-mob-active-bg-color;
							color: $nav-mob-active-text-color;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center /14px 14px transparent;
							}
							&:hover {
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center / 14px 14px transparent;
								} 
							}
						}

						&.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							border-bottom: $nav-hover-border;
							padding:$nav-sub-base-hover-padding;
							
//							// LEVEL BORDERS: TOP  //
//							border-top: 1px solid $nav-mob-1st-border-color;
							
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center / 20px 20px  transparent;						
							}
							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center / 20px 20px transparent;
								}	
							}
						}

						&.active-trail,
						&.current.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							//border-bottom: $nav-highlight-border;
							padding:$nav-sub-base-hover-padding;

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}
						}

						&.active.highlighted {
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center / 14px 14px transparent;
							}
							&:hover {
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center  / 14px 14px transparent;
								}
							}
						} 
					}
				}
				
				li {
					border: none;
					
					a {
						border-bottom: $nav-mob-border;
						border-left: none;
						padding-left: 1.111rem;
						
						color: white;
						.sub-arrow {
							background:$arrow-white-right no-repeat scroll center center / 12px 20px transparent;
							height: 46px;
							margin-top: -23px;
							//border-left: $nav-mob-border;
						}
					}
					
					
					///////////////////////////////      NEXT LEVELS      ///////////////////////////////
					
					/* ul BACKGROUNDS */
					&.expanded {
						
						// 2nd level //
						ul.menu,
						ul.menu a.current {
							background-color: $nav-mob-2nd-bg-color;
							//border-top: 2px solid $nav-mob-1st-border-color;	
							
							li.first a {
								border-top:none;
							}

							li:not(.last) a {
								border-bottom: 1px solid $nav-mob-2nd-line-color;
							}
							
//							// LEVEL BORDERS: BOTTOM (TOP: Look 2nd level a.highlighted)  //
//							li.last {					
//								border-bottom: 1px solid $nav-mob-1st-border-color;
//								margin-bottom: -1px;
//								z-index: 1;
//								position: relative;
//							}
							
							a {
								border-left: none;
								padding-left: 1.375rem;								
							
								&.active {
									background-color: $nav-mob-active-bg-color;
									color: $musk_melon;						
								}
							}

							li.expanded {
								
								// 3rd level //
								ul.menu {	
									background-color: $nav-mob-3rd-bg-color;
									//border-top: 2px solid $nav-mob-2nd-border-color;	

									li.first a {
										border-top:none;
									}

									li:not(.first) a {
										border-top: 1px solid $nav-mob-3rd-line-color;
									}
							
//									// LEVEL BORDERS: BOTTOM (TOP: Look 3rd level a.highlighted)  //
//									li.last {					
//										border-bottom: 1px solid $nav-mob-2nd-border-color;
//										z-index: 2;
//									}
							
									a {
										border-left: none;
										padding-left: 1.875rem;								

										&.active {
											background-color: $nav-mob-active-bg-color;
											color: $nav-mob-active-text-color;						
										}
									}
								}

								li.expanded {
									
									// 4th level //
									ul.menu {	
										background-color: $nav-mob-4th-bg-color;	
										border-top: 2px solid $nav-mob-3rd-border-color;	

										li.first a {
											border-top:none;
										}

										li:not(.first) a {
											border-top: 1px solid $nav-mob-4th-line-color;
										}
										
//										// LEVEL BORDERS: BOTTOM (TOP: Look 4th level a.highlighted)  //
//										li.last {				
//											border-bottom: 1px solid $nav-mob-3rd-border-color;
//											z-index: 3;											
//										}
							
										a {
											border-left: none;
											padding-left: 2.375rem;								

											&.active {
												background-color: $nav-mob-active-bg-color;
												color: $nav-mob-active-text-color;
											}
										}
									}
								}
							}
						}
					}
					
					/* ARROWS + HIGHLIGHT */ 
					ul.menu {	
						box-shadow: none;
						
						li {
							//border-top:$nav-mob-expanded-border;
							border-top: none;
							
							a {
								color: $nav-mob-text-color;
								&.active-trail {
									color:$musk_melon;
									.sub-arrow {
										background: $arrow-melon-down no-repeat scroll center center / 14px 14px transparent;
										right:0;
										height: 39px;
										margin-top: -19px;
									}
									&:hover {
										color:$cerise_red;
										.sub-arrow {
											background: $arrow-cerise-down no-repeat scroll center center / 14px 14px transparent;
										}
									}
								}
								.sub-arrow {
									background:$arrow-grey-down no-repeat scroll center center / 14px 14px transparent;
									right:0;
									height: 39px;
									margin-top: -19px;
									//border-left: $nav-mob-border;
								}	
															
								&.active {
									.sub-arrow {
										background:$arrow-melon-right no-repeat scroll center center / 14px 14px transparent;
									}
								}

								&:hover, 
								&.active:hover  {
									.sub-arrow {
										background:$arrow-cerise-right no-repeat scroll center center / 14px 14px transparent;
									}								
								}
							}
							
							&.expanded {								
								ul.menu a.current {
									background-color: $nav-mob-2nd-highlight-bg-color;

									&.active {
										background-color:$frosty_mint;
										color:$musk-melon;
										&:hover {
											color:$cerise_red;
											&:after {
												display: inline-block;
												height: 8px;
												width: 8px;
												content: "";
												background: $ico-circle-menu-cerise no-repeat 50% 50% /8px 8px;
												position: absolute;
												bottom: calc(50% - 4px);
												left: 5px;
											}
										}
										.sub-arrow {
											background:$arrow-cerise-down no-repeat scroll center center / 14px 14px transparent;
										}
									}

									&:hover {
										//background-color:$nav-sub-hover-bg-color;
										//color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center /14px 14px transparent;
										}
									}
								}
								
								////////////**     2nd LEVEL     **////////////
								& > a.highlighted { 
									background-color:$nav-mob-2nd-highlight-bg-color;
									color:$nav-mob-2nd-highlight-text-color!important;
									&:hover {
										color:$cerise_red!important;
									}
									&:after {
										display: inline-block;
										height: 8px;
										width: 8px;
										content: "";
										background: $ico-circle-menu-melon no-repeat 50% 50% / 8px 8px;
										position: absolute;
										bottom: calc(50% - 4px);
										left: 5px;
									}
									
//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-2nd-border-color;

									.sub-arrow {
										background:$arrow-grey-down no-repeat scroll center center / 14px 14px transparent;
									}

									&.current.active {
										.sub-arrow {
											background:$arrow-grey-down no-repeat scroll center center / 14px 14px transparent;
										}
									}
									&:hover {
										.sub-arrow,
										&.current.active .sub-arrow {
											background:$arrow-cerise-down no-repeat scroll center center / 14px 14px transparent;
										}
									}
									
								}

								////////////**     3rd LEVEL     **////////////
								& > ul.menu > li.expanded > a.highlighted { 
									background-color: $nav-mob-3rd-highlight-bg-color;
									color: $nav-mob-3rd-highlight-text-color;
									
//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-3rd-border-color;

									.sub-arrow {
										background:$arrow-black-up no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover, 
									&.current.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}								
								}

								////////////**     4th LEVEL     **////////////
								& > ul.menu > li.expanded > ul.menu > li.expanded > a.highlighted { 
									background-color: $nav-mob-4th-highlight-bg-color;
									color: $nav-mob-4th-highlight-text-color;
									
//									// LEVEL BORDERS: TOP  //
//									border-top: 1px solid $nav-mob-4th-border-color;

									.sub-arrow {
										background:$arrow-black-up no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover, 
									&.current.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-up no-repeat scroll center center transparent;
											background-size:50%;
										}
									}								
								}
							}
						}
					}
				}
			}
		}
	}
}