.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		position: relative;
		
		.file-icon {
			float:left;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			position: absolute;
			z-index: 10;
			.file-extension {
				padding:$vert-space*0.3 $horz-space/4;
        line-height:1;
				text-align:center;
				font-weight:bold;
				padding: 18px 5px;
		    text-transform: lowercase;
		    font-size: 1.111rem;
		    font-weight: 600;
		    border-radius: 50%;
		    height: 60px;
		    width: 60px;
			}
		}
		
		.file-name {
			overflow:visible;
			font-weight: 600;
    	font-size: 1.111em;
			padding: 10px 10px 0 50px;
			position: relative;
    	left: 30px;
			width: calc(100% - 30px);
			z-index: 0;
		}
		.file-size {
			font-size:0.7778em;
			position: relative;
			left: 30px;
			padding-left: 50px;
			padding-bottom:20px;
			width: calc(100% - 30px);
			color:$primary;
			&:after {
				content: "";
			  display: inline-block;
			  height: 10px;
			  width: 100%;
	      position: absolute;
		    bottom: 0px;
		    left: 0;
			}
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_color;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_color;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_color;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_color;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_color;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_color;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_color;
      color:white;
    }
  }
} 

// RESPONSIVE STYLING

.view-attachments {
	.views-row {
		width:100%;
		float:left;

		@media (min-width:720px) {
			width:47%;
			margin-right:15px;
		}
		
		&:nth-child(2n+1){
			@media (min-width:720px) and (max-width:1199px) {
				clear:left;
			}
		}

		@media (min-width:1200px) {
			width:23%;
			margin-right:15px;
		}
		&:nth-child(4n+1){
		 	@media (min-width:1200px) {
				clear:left;
			}
		}
	}
}

.side-row .view-attachments .views-row {
	width:100%;
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments	.views-row {
			width:100%;
			@media (min-width:960px) {
				width:48%;
				margin-right:15px;
				&:nth-child(2n+1) {
					clear:left;
				}
				&:nth-child(2n) {
					margin-right:0;
				}
			}
		}
	}
}

.palette-default,
main {
	.view-attachments .attachment-link {
		.file-name,
		.file-size {
			background: $card-medium-bg-color;
		}
		.file-size:after {
			background:$card-medium-border-color;
		}
	}
}

.palette-alt-3 {
	.view-attachments .attachment-link {
		.file-name,	
		.file-size {
			background: $pine_green;
			color:white;
		}
		.file-size:after {
			background:$pastel_blue;
		}
		&:hover {
			.file-name,	
			.file-size {
				background: $daphne;
				color:white;
			}
			.file-size:after {
				background:$pale_cerulean;
			}
		}
	}
}

.postscript-first .section-row.palette-highlight,
.postscript-first .section-row.palette-alt-1,
.postscript-first .section-row.palette-alt-2,
.side-row {
	.view-attachments .attachment-link {
		.file-name {
			background: $card-light-bg-color;
			color:$action-default;
		}
		.file-size {
			background: $card-light-bg-color;
		}
		&:hover {
			.file-name {
				color:$cerise_red;
			}
		}
	}
}

.palette-alt-2,
.palette-alt-1 {
	.view-attachments .attachment-link {
		.file-size:after {
			background:$card-light-border-color;
		}
	}
}

.palette-highlight {
	.view-attachments .attachment-link {
		.file-size:after {
			background:$card-highlight-border-color;
		}
	}
}
