button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 8px 45px 8px 25px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  text-align: center;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a,
a.slide-link-button {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 6px 40px 6px 15px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  margin-bottom: 5px!important;
  &:after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    z-index: 10;
    right: -11px;
    bottom: -4px;
  }
  &:before {
    content: "";
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background:white;
    position: absolute;
    right: -15px;
    z-index: 0;
    bottom: -8px;
  }
}

a.slide-link-button {
  color:white!important;
  background:$daphne;
  &:after {
    background: $arrow-daphne-right no-repeat scroll 50% 50% / 9px auto;
    border:solid 4px $daphne;
  }
  &:hover {
    background: $cerise_red;
    &:after {
      background: $arrow-cerise-right no-repeat scroll 50% 50% / 9px auto;
      border:solid 4px $cerise_red;
    }
  }
}

.palette-highlight {
  a.button,
  .button a {
    &:after {
      background: $musk_melon $button-link-alt-icon;
    }
    &:before {
      background:$musk_melon;
    }
  }
}

.palette-default,
.palette-alt-3 {
  a.button,
  .button a {
    &:after {
      background: $white $button-link-default-icon;
    }
    &:before {
      background:$white;
    }
  }
}

.palette-alt-1,
.side-row {
  a.button,
  .button a {
    &:after {
      background: $frosty_mint $button-link-default-icon;
    }
    &:before {
      background:$frosty_mint;
    }
  }
}

.palette-alt-2 {
  a.button,
  .button a {
    &:after {
      background: $clear_vision $button-link-default-icon;
    }
    &:before {
      background:$clear_vision;
    }
  }
}