// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: white $arrow-cerise-left no-repeat scroll 50% 50%;
$slick-default-prev-hover-bg      				: white $arrow-melon-left no-repeat scroll 50% 50%;
$slick-default-next-bg            				: white $arrow-cerise-right no-repeat scroll 50% 50%;
$slick-default-next-hover-bg      				: white $arrow-melon-right no-repeat scroll 50% 50%;
$slick-default-border               			: 4px solid rgba(214, 47, 95, 0.2); 
$slick-default-hover-border              	: 4px solid rgba(240, 145, 95, 0.2);
$slick-default-dot-border         				: 3px solid $dark_silver;
$slick-default-dot-hover-border    				: 3px solid $musk_melon;
$slick-default-dot-active-bg-color 				: $cerise_red;
$slick-default-dot-active-border         	: 3px solid $cerise_red; 
$slick-default-dot-active-hover-bg-color 	: $musk_melon;


$slick-alt-1-nav-bg                				: transparent; // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: $arrow-cerise-left no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg         				: $arrow-melon-left no-repeat scroll 50% 50%;
$slick-alt-1-next-bg               				: $arrow-cerise-right no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg         				: $arrow-melon-right no-repeat scroll 50% 50%;
$slick-alt-1-border               				: 4px solid rgba(214, 47, 95, 0.2); 
$slick-alt-1-hover-border               	: 4px solid rgba(240, 145, 95, 0.2);
$slick-alt-1-dot-default-border    				: 3px solid $dark_silver;
$slick-alt-1-dot-hover-border      				: 3px solid $musk_melon;
$slick-alt-1-dot-active-bg-color   				: $cerise_red;
$slick-alt-1-dot-active-border          	: 3px solid $cerise_red;
$slick-alt-1-dot-active-hover-bg-color 		: $musk_melon;


$slick-alt-2-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $arrow-apple-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: $arrow-apple-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-border               				: 4px solid rgba(163, 9, 0, 0.2); 
$slick-alt-2-hover-border               	: 4px solid rgba(255, 255, 255, 0.2);
$slick-alt-2-dot-default-border    				: 3px solid $secondary;
$slick-alt-2-dot-hover-border      				: 3px solid white;
$slick-alt-2-dot-active-bg-color   				: $apple_red;
$slick-alt-2-dot-active-border          	: 3px solid $apple_red;
$slick-alt-2-dot-active-hover-bg-color 		: white;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : white;
$slide-text-color          : $primary;
$slide-text-font           : bold 1.222rem/1.45 $title-font-family;