table {
	border-collapse: separate;
	border-spacing: 0;
	margin: 0 0 $vert-space;
	width: auto;
	display: block;
	
	caption {
		background:$table-caption-bg-color;
		border-bottom:$table-caption-border;
		color::$table-caption-text-color;
		font: $table-caption-font;
		padding:$vert-space/3 $vert-space/2;
	}

	th {
		background:$table-header-bg-color;
		border-bottom: $table-header-border;
		font-weight: bold;
		padding: $vert-space/2 0;
		text-align: left;
		word-wrap: break-word;
	}

	td {
		border-bottom: $primary-border;
		line-height: $base-line-height;
		padding: $table-padding;
		border-left:$table-border;
		border-right:$table-cell-border;
		word-wrap: break-word;
		&:last-child {
			border-right:$table-border;
		}
	}

	thead {
		th {
			background-color: $table-header-bg-color;
			color:white;
			border-bottom: 0px;
			padding: $table-padding;
			font-size: 1.222em;
			border-right:$table-cell-border;
			border-bottom:$table-cell-border;
			&:last-child {
				border-right:none;
			}
		}
	}
	
	tr,
	td,
	th {
		vertical-align: middle;
	}

	tbody {
		background-color: $table-bg-color;

		tr:hover > td, tr:hover > th {
			background-color: $table-hover-bg-color;
		}
		tr:first-child td {
			border-top: $table-header-border;
		}
		tr:last-child td {
			border-bottom: $table-border;
		}
		td {
			border-bottom: $table-cell-border;
			//border-top: $table-border;
			padding: $table-padding;

			button {
				display: inline-block;
				font-size: .7em;
				line-height: $base-line-height;
				margin-bottom: .3em;
				margin-right: 0.5em;
				outline: none;
				padding: .3em 1em;
				width: 100%;

				@include media($narrow) {
					margin-bottom: 0;
					width: auto;
				}
			}
		}
	}
}

.palette-default {
	table {
		td {
			border-right:1px solid $pine_green;
			&:last-child {
				border-right:none;
			}
		}
		thead th {
			background-color: $frosty_mint;
			color:$primary;
			border-right:1px solid $pine_green;
			border-bottom:1px solid $pine_green;
			&:last-child {
				border-right:none;
			}
		}
		tbody td {
			border-bottom: 1px solid $dark_silver;
		}
	}
}

.palette-alt-1,
.palette-alt-2,
.side-row {
	table thead th {
		background-color: $pine_green;
	}
}

.palette-highlight {
	table thead th {
		background-color: $apple_red;
	}
}

