.credit,
.vid-description {
	background: white;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: right;
	font-size: 0.75em;
	margin-bottom: -27px;
	margin: 8px 0;
	padding: 5px 40px 10px 20px;
	position: absolute;
	right: 30px;
	text-align: right;
	top: 20px;
	height:40px;
	font-size: 1em;
	width:auto;
	
	&:after {
		background: white $ico-info-close-daphne no-repeat 50% 50%;
		display: inline-block;
		content:"";
		height: 40px;
		width: 40px;
		border:solid 4px $daphne;
		border-radius: 50%;
		position: absolute;
		right: -20px;
		top: 0;
	}
	&:after:hover {
		border:solid 4px $cerise_red;
		background: white $ico-info-close-cerise no-repeat 50% 50%;
	}
	
	&:empty {
		display: none;
	}

	&.collapsed {
		background: white $ico-info-daphne no-repeat 50% 50%;
		height: 40px;
		margin-right: 0;
		text-align: inherit;
		text-indent: -9999px;
		transition: background-color 0.5s linear;
		width:40px;
		border-radius: 50%;
		border:solid 4px $daphne;
		padding:0;

		&:hover {
			background: white $ico-info-cerise no-repeat 50% 50%;
			border:solid 4px $cerise_red;
		}
		&:after {
			display:none;
		}
	}
}