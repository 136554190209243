// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:100%;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }


// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:15px;
			top:-50px;
			background-size:contain;
			// @include media(960px) {
			// 	left:5px;
			// }
			@include media(1200px) {
				left:0;
			}
		}
		&.slick-next {
			right:15px;
			top:-50px;
			background-size:contain;
			// @include media(960px) {
			// 	right:5px;
			// }
			@include media(1200px) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		@media(min-width:960px) {
			position: static;
		}
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				padding:3px;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;

	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 20px;
		&.slick-next,
		&.slick-prev {
			top: 28px;
		}
	}

	.slick-dots {
		bottom:17px;
	}
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}



// /* SLICK NAV ONLY
.slick-nav {
	height: 0;
	&:empty { display: none; }
	button.slick-arrow {
		padding: 20px;
	}
	.slick-dots {
		bottom:-10px;
		@media(min-width:960px){
			margin-top:20px;
		}
		@media(min-width:1200px){
			margin-top:75px;
		}
	}
}



.content-hero .section-row,
.postscript_first .section-row {
	&.palette-default,
	&.palette-alt-1,
	&.palette-alt-2,
	&.palette-alt-3,
	&.palette-highlight {
		.view-slider .slick-nav {
			button.slick-arrow {
				&.slick-prev,
				&.slick-next {
					background-color: white!important;
					border:solid 4px white;
				}
			}
		}
	}
}


// SLIDER VIEWS
.view-slider {
	max-width: 1600px;
	position: relative;
	margin: 0 auto;
	
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-color;

				@include media($narrow) {
					background-color: transparent;
				}

				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								//left:calc(50% - 800px);
								left:-10px;
								width: auto;
							}
						}
					}
					.card-description-inner {
						position: relative;
						@media(min-width:960px) {
							position: static;
						}
						.card-description-border {
							display: inline-block;
							background: #137777;
							mix-blend-mode: multiply;
							content: "";
							height: 228px;
							width: calc(100% - 20px);
							position: absolute;
							top: -10px;
							z-index: 0;
							left: 10px;
							border-radius: 20px;
							@include media(960px) {
								max-width: 340px;
								height: 340px;
								border-radius: 50%;
								top: calc(50% - 170px);
								left: 20px;
							}
							@include media(1200px) {
								max-width: 480px;
    						height: 480px;
								top: calc(50% - 260px);
    						left: 15px;
							}
						}
						.card-slide-description {
							bottom:0;
							position:relative;
							background-color:$slide-text-bg-color;
							font:$slide-text-font;
							padding:10px 20px;
							margin:-20px auto 30px;
							color:$slide-text-color;
							min-height:208px;
							max-width:calc(100% - 40px);
							border-radius: 20px;
							border:solid 10px $pine_green;
							text-align: center;
							// margin-top:-20px;

							p {
								font:$slide-text-font;
								margin: 0;
							}

							@include media(960px) {
								width: 300px;
								height: 300px;
								padding: 50px 40px 60px;
								border-radius: 50%;
								position: absolute;
								top: calc(50% - 130px);
								left: 40px;
								font-size: 1rem;
								border:solid 20px $pine_green;

								p {
									font-size: 1rem;
								}
							}

							@include media(1200px) {
								width: 430px;
    						height: 430px;
    						padding: 70px 40px;
								top:calc(50% - 215px);
								font-size: 2rem;

								p {
									font-size: 2rem;
								}
							}
						}

						.card-slide-link-button {
							position: relative;
							top: -120px;
							left: calc(50% - 85px);
							width: 170px;
							display: inline-block;
							@media(min-width:960px) {
								position: absolute;
								top: calc(100% - 38%);
								left: 100px;
							}
							@media(min-width:1200px) {
								top: calc(100% - 40%);
					    	left: 160px;
							}
						}
					}
				}
			}
		}

		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}

	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:100px;
		width:100%;
		left:0;
		height:0;
		@include media(960px) {
			top: calc(50% + 60px);
			width:320px;
			left:40px;
		}
		@include media(1200px) {
			top: calc(50% + 30px);
			width: 420px;
			left: 55px;
		}
	}
}

.palette-alt-2 {
	.view-slider .view-content .slick-list .slick-track .views-row .card-description-inner {
		.card-description-border {
			background: #07588c;
		}
		.card-slide-description {
			border-color:$daphne;
		}
	}
}
.palette-highlight {
	.view-slider .view-content .slick-list .slick-track .views-row .card-description-inner {
		.card-description-border {
			background: #ed7c40;
		}
		.card-slide-description {
			border-color:$musk_melon;
		}
	}
}


.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}


// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

// .front,
// .not-front .content-hero {
// 	.section-row.section-with-slider .section-inner {
// 		margin: 0 auto;
// 		max-width:100%; //$site-max-width;
// 		padding:0;
// 		position: relative;
//
// 		@media (min-width:1600px) {
// 			max-height: 450px;
// 			overflow: hidden;
//
// 			.view-slider .view-content .slick-list .slick-track .views-row {
// 				margin: 0;
//
// 				.card-image {
// 					.card-slide-image {
// 						position:relative;
// 						img {
// 							max-width:none;
// 							position:relative;
// 							width: 100%;
//
// 							@include media($narrow) {
// 								left: -10px;
// 								width: calc(100% + 20px);
// 							}
//
// 							@include media($wide) {
// 								left:calc(50% - 800px);
// 								width: auto;
// 							}
//
// 							@media (min-width:1600px) {
// 								left:0;
// 								width: 100%;
// 							}
// 						}
// 					}
// 				}
//
// 				.card-slide-description {
// 					bottom:0;
// 					position:relative;
// 					background-color:$slide-text-bg-color;
// 					font:$slide-text-font;
// 					padding:17px 20px 55px 20px;
// 					margin:0;
// 					color:$slide-text-color;
// 					min-height:66px;
//
// 					// @include media($narrow) {
// 					// 	background-color:$slide-text-bg-color;
// 					// 	position:absolute;
// 					// 	padding:17px 20px;
// 					// 	max-width:75%;
// 					// }
// 					// @include media(1600px) {
// 					// 	top: calc(450px - 66px);
// 					// 	bottom:auto;
// 					// 	left: calc(50% - 800px + 20px);
// 					// 	max-width:50%;
// 					// }
// 				}
// 			}
// 		}
//
// 		.view-slider .slick-slider:not(.slick-dotted) {
// 			.card-description-inner.card-description-empty {
// 				display: none;
// 			}
// 		}
//
// 		.view-slider .view-footer {
// 			@include media(1600px) {
// 				top: calc(450px - 117px);
// 				bottom:auto;
// 				left: calc(50% - 800px + 20px);
// 				max-width: calc(1600px - 40px);
// 			}
// 		}
// 	}
// }


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;

			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}

	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
		margin-top: 20px;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	.view-footer {
		text-align:center;
	}
}

.card-slide-content-title {
	display:none;
}
