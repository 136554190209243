// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #0A2F46; /* gibralter_sea */ 
$secondary         : #40403C; /* black_ink */ 



// /* MAIN PALETTE */ //
$dogwood_rose      : #D81E5B; /* dogwood_rose */ /// MOBILE MENU
$light_blue        : #84D0F5; /* lightblue */
$faded_blue        : #5D86C5; /* faded_blue */ /// MOBILE MENU
$middle_red_purple : #38023B; /* middle_red_purple */

$cerise_red        : #D62F5F; /* primary */
$pine_green        : #157F7B; /* secondary*/
$aspen_gold        : #FED361; /* logo-yellow */


// /* SEC PALETTE */ //
$pale_grey         : #f5f8fa; /* pale_grey */
$pale_grey_two     : #e4e8eb; /* pale_grey_two */
$battleship_grey   : #6b7280; /* battleship_grey */
$japanese_indigo   : #6b7280; /* japanese_indigo */
$platinum          : #e0e0e2; /* platinum */
$pearl_aqua        : #81D2C7; /* pearl_aqua */  /// MOBILE MENU
$dark_lavender     : #6250A1; /*  dark_lavender  */  /// MOBILE MENU

// Palettes //
//$white             : #FFFFFF; /* default + alt-3 */           
$musk_melon        : #F0915F; /* highlight */
$frosty_mint       : #E4F3F2; /* alt-1 */
$clear_vision      : #E7EFF6; /* alt-2 */

// Text //
$daphne            : #08629C; /* link */
$gibralter_sea     : #0A2F46; /* text-titles */
$black_ink         : #40403C; /* text-body */
$dark_silver       : #706F6F; /* text-info */
$apple_red         : #A30900; /*  link-highlight */
$raisin_black      : #231F20; /* labels */

// Cards //
$bleached_silk     : #F3F3F3; /* light */
$blue_lobelia      : #21314A; /* dark */
$dark_denim        : #37455C; /* header hover */

$pale_cerulean     : #9CC0D7; /* frosty_mint border */
$pastel_blue       : #A1CCCA; /* daphne border */
$peach             : #F6BD9F; /* highlight palette white border */
$mint_cream        : #FAFAFA; /* default palette white border */
$white_smoke       : #F1F5FA; /* alt-2 palette white border */
$alice_blue        : #EFF8F7; /* sidebar white border */

$black             : #000;
$grey              : #666;
$white             : #FFF;

// MENU

$jelly_bean					: #E46747;  /// MOBILE MENU

$middle_red					: #E28871;

$vivid_tanherine		: #EFA491;
$champagne_pink			: #F8D9D1;

$thulian_pink				: #E8789D;
$queen_pink					: #F5C7D6;

$pale_cerulean			: #9EB6DC;
$azureish_white			: #D6E1F0;

$blue_bell					: #A196C7;
$languid_lavander		: #D8D3E7;


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $daphne;
$action-hover        : $cerise_red;
$action-active       : $cerise_red; 
$action-active-hover : $cerise_red; 


// /* SHADOWS */ //
$box-shadow : 0 2px 4px 0 rgba(0,0,0,0.12);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA;
$table-border-color       : #F3F3F3;
$table-cell-border        : 1px solid $primary;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $daphne;
$table-header-border      : 1px solid darken($primary-border-color, 10%);
$table-hover-bg-color     : lighten($platinum, 10%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : $box-shadow;
$form-box-shadow-focus : $box-shadow; 
$form-border-color     : $secondary-border-color;
$form-border           : 1px solid $secondary-border-color;
$form-border-radius    : 0;

$button-bg             : $daphne;
$button-hover-bg       : $action-hover;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 30px;
$button-hover-text     : white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;