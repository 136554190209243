// /* MAIN MENU */ //

$nav-base-font        	 	: 600 1.2rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 600 1.222rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: transparent;
$nav-base-bg-color        		: $blue_lobelia; 
$nav-base-text-color     		 	: white;
$nav-base-padding        			: 0.625rem 1.111rem;
$nav-base-hover-padding   		: 0.625rem 1.111rem;
$nav-sub-base-padding					: 0.625rem 1.111rem;
$nav-sub-base-hover-padding		: 0.625rem 1.111rem;

$nav-base-padding-smaller 				: 0.675rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.675rem 1.125rem;

$nav-active-bg-color      		: $dark_denim;
$nav-active-text-color    		: $musk_melon;
$nav-active-border-radius 		: 0; 

$nav-hover-bg-color       		: $dark_denim;
$nav-hover-text-color     		: $aspen_gold;
$nav-hover-border	  	    		: none;

$nav-highlight-bg-color   		: $dark_denim;
$nav-highlight-text-color 		: white; 
$nav-highlight-border	    		: none;

$nav-sub-bg-color         		: white;
$nav-sub-hover-bg-color   		: $blue_lobelia;
$nav-sub-text-color       		: $secondary;
$nav-sub-hover-text-color   	: $cerise_red;
$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

$nav-mob-text-color      						: $secondary;
$nav-mob-active-text-color      		: white;
$nav-mob-active-bg-color      			: $dark_denim;

$nav-mob-1st-bg-color     					: $blue_lobelia;
$nav-mob-1st-highlight-bg-color     : $dark_denim;
$nav-mob-1st-highlight-text-color   : white;
$nav-mob-1st-line-color     				: red;
$nav-mob-1st-border-color     			: $jelly_bean;

$nav-mob-2nd-bg-color     					: white;
$nav-mob-2nd-highlight-bg-color     : $frosty_mint;
$nav-mob-2nd-highlight-text-color   : $secondary;
$nav-mob-2nd-line-color     				: $bleached_silk;
$nav-mob-2nd-border-color     			: $dogwood_rose;

$nav-mob-3rd-bg-color     					: $frosty_mint;
$nav-mob-3rd-highlight-bg-color     : $pale_cerulean;
$nav-mob-3rd-highlight-text-color   : $secondary;
$nav-mob-3rd-line-color     				: white;
$nav-mob-3rd-border-color     			: $faded_blue;

$nav-mob-4th-bg-color     					: $azureish_white;
$nav-mob-4th-highlight-bg-color     : $blue_bell;
$nav-mob-4th-highlight-text-color   : $primary;
$nav-mob-4th-line-color     				: purple;
$nav-mob-4th-border-color     			: $dark_lavender;

$nav-mob-border											: 1px solid $dark_denim;
$nav-mob-expanded-border						: 1px solid red;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: red;
$nav-button-hover-bg-color			: $blue_lobelia;
$nav-button-height      				: 100px;
$nav-button-width      					: 86px;
$nav-button-bar-bg-color      	: $blue_lobelia;
$nav-button-bar-hover-bg-color  : white;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 56px;
$nav-button-bar-border-radius 	: 8px;
