@import url(https://use.typekit.net/llr4anw.css);
//<link rel="stylesheet" href="https://use.typekit.net/llr4anw.css">



$base-font-family      		: 'futura-pt', sans-serif;
$title-font-family    		: 'futura-pt', 'Georgia', serif;
$subtitle-font-family 	 	: 'futura-pt', 'Georgia',serif;

$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$button-link-font 				: 600 1.111rem/1 $base-font-family;

/* FORMS */
$button-font 							: 600 1.111rem/1 $title-font-family;