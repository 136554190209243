// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $base-font-family;
$card-title-font        	: 600 1.222rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: 400 1.222rem/1.22222222222222 $base-font-family;
$card-more-link-font			: 600 1.222rem/1 $base-font-family;
$card-feed-link-font			: 600 1.111rem/1.46153846153846 $base-font-family;


$card-shadow                   : none;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : transparent;
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $dark_silver;
$card-date-font                : 0.7778rem/1 $base-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color           : transparent;
$card-hover-bg-color-alt       : $daphne;
$card-hover-border             : none;
$card-hover-shadow             : none;
$card-action-hover             : $cerise_red; //colour of card title text when you hover over it
$card-text-hover-color         : $platinum; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $dark_silver;
$card-date-hover-bg-color      : transparent; // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : $alice_blue;
$card-light-title-color        : $primary;
$card-light-action-default     : $action-default;
$card-light-action-hover       : $action-hover;
$card-light-text-color         : $secondary;
$card-light-subtitle-color     : $secondary; 

// /* --- CARD-LIGHT-ALT - light coloured background with different border*/
$card-light-alt-border-color   : $white_smoke;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $bleached_silk;
$card-medium-border-color      : $mint_cream;
$card-medium-title-color       : $primary;
$card-medium-action-default    : $action-default;
$card-medium-action-hover      : $action-hover;
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $secondary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $frosty_mint;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $white;
$card-dark-title-bg            : $pine_green;
$card-dark-action-default      : $action-default;
$card-dark-action-hover        : $action-hover;
$card-dark-text-color          : $secondary;
$card-dark-subtitle-color      : $secondary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : white;
$card-highlight-border-color   : $peach;
$card-highlight-title-color    : $primary;
$card-highlight-action-default : $action-default;
$card-highlight-action-hover   : $action-hover;
$card-highlight-text-color     : $secondary;
$card-highlight-subtitle-color : $secondary;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $bleached_silk;
$card-more-link-default-color       : $gibralter_sea;
$card-more-link-default-icon        : $arrow-gibralter-right no-repeat 100% 100% / 12px auto;
$card-more-link-default-hover-bg    : $bleached_silk;
$card-more-link-default-hover-color : $cerise_red;
$card-more-link-default-hover-icon  : $arrow-cerise-right no-repeat 100% 100% / 12px auto;

$card-more-link-alt-bg              : $frosty_mint;
$card-more-link-alt-color           : $gibralter_sea;
$card-more-link-alt-icon            : $arrow-gibralter-right no-repeat 100% 100% / 12px auto;
$card-more-link-alt-hover-bg        : $clear_vision;
$card-more-link-alt-hover-color     : $gibralter_sea;
$card-more-link-alt-hover-icon      : $arrow-gibralter-right no-repeat 100% 100% / 12px auto;

$card-more-link-alt-2-bg             : $white;
$card-more-link-alt-2-color          : $gibralter_sea;
$card-more-link-alt-2-icon           : $arrow-gibralter-right no-repeat 100% 100% / 12px auto;
$card-more-link-alt-2-hover-bg       : $white;
$card-more-link-alt-2-hover-color    : $cerise_red;
$card-more-link-alt-2-hover-icon     : $arrow-cerise-right no-repeat 100% 100% / 12px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : $aspen_gold; 
$card-feed-link-default-color       : $primary;
$card-feed-link-default-icon        : $arrow-gold-right no-repeat scroll 50% 50% / 9px auto;
$card-feed-link-default-hover-bg    : $cerise_red;
$card-feed-link-default-hover-color : white;
$card-feed-link-default-hover-icon  : $arrow-cerise-right no-repeat scroll 50% 50% / 9px auto;
$card-feed-link-default-border      : solid 4px $aspen_gold;
$card-feed-link-default-hover-border: solid 4px $cerise_red;

$card-feed-link-alt-bg              : $apple_red;
$card-feed-link-alt-color           : white;
$card-feed-link-alt-icon            : $arrow-apple-right no-repeat scroll 50% 50% / 9px auto;
$card-feed-link-alt-hover-bg        : white;
$card-feed-link-alt-hover-color     : $apple_red;
$card-feed-link-alt-hover-icon      : $arrow-white-right no-repeat scroll 50% 50% / 9px auto;
$card-feed-link-alt-border          : solid 4px $apple_red;
$card-feed-link-alt-hover-border    : solid 4px white;

$card-feed-link-alt-1-bg            : $daphne;
$card-feed-link-alt-1-color         : white;
$card-feed-link-alt-1-icon          : $arrow-daphne-right no-repeat scroll 50% 50% / 9px auto;
$card-feed-link-alt-1-hover-bg      : $cerise_red;
$card-feed-link-alt-1-hover-color   : white;
$card-feed-link-alt-1-hover-icon    : $arrow-cerise-right no-repeat scroll 50% 50% / 9px auto;
$card-feed-link-alt-1-border        : solid 4px $daphne;
$card-feed-link-alt-1-hover-border  : solid 4px $cerise_red;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $action-default;
$button-link-default-border-color       : $action-default;
$button-link-default-color              : white;
$button-link-default-icon               : $arrow-daphne-right no-repeat 50% 50% / 12px auto;
$button-link-default-hover-bg           : $action-hover;
$button-link-default-hover-border-color : $action-hover;
$button-link-default-hover-color        : none;
$button-link-default-hover-icon         : $arrow-cerise-right no-repeat 50% 50% / 12px auto;

$button-link-alt-bg                     : $apple_red;
$button-link-alt-border-color           : $apple_red;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : $arrow-apple-right no-repeat 50% 50% / 12px auto;
$button-link-alt-hover-bg               : white;
$button-link-alt-hover-border-color     : white;
$button-link-alt-hover-color            : $apple_red;
$button-link-alt-hover-icon             : $arrow-white-right no-repeat 50% 50% / 12px auto;