.Treant {
  > .node {
  }

  > p {
    font-weight: bold;
    font-size: 12px;
  }

  .node-name {
    font-weight: bold;
    text-align: center;
  }

  .tree-node {
    padding: 10px 5px 0px;
    border-radius: 10px;
    border: 4px solid $daphne;
    width: 200px;
    font-family: $base-font-family;
    background-color: $daphne;
    color: white;
    &:hover {
      background: $cerise_red;
      border-color: $cerise_red;
      color: white;
    }

    &.gray {background-color: $platinum ;}
    &.light-gray {
      background-color: $white;
      color:$daphne;
      &:hover {
        color: $cerise_red;
      }
    }
    &.blue {background-color: $pale_cerulean;}

    img {
      margin-right: 10px;
    }

    .collapse-switch {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
    }

    &.collapsed {
      // background-color: $daphne;
      // border-color: $daphne;
      // color: white;
      // &:hover {
      //   background: $cerise_red;
      //   border-color: $cerise_red;
      //   color: white;
      // }

      .collapse-switch {
        background: none;
      }
    }
  }
}

.section-row.palette-highlight {
  .Treant a.tree-node:hover {
    color:$cerise_red;
  }
}
.container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
