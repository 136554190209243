/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;
	.views-row {
		margin-bottom:15px;
		margin-left: 10px;
	}

	.card-qa-answer {
		display: none;
		padding: 10px 10px 10px 45px;
		box-sizing: border-box;

		:last-child {
			margin-bottom: 0;
		}
	}

	.card-qa-question {
		text-decoration: none;
		color: $daphne;
		padding: 10px 0 10px 45px;
		line-height: 20px;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		font-weight: 600;
		font-size: 1.111em;
		background: $ico-expand-daphne no-repeat scroll 0 50% / 24px 24px transparent;
		&:hover {
			color:$cerise_red;
			background: $ico-expand-cerise no-repeat scroll 0 50% / 24px 24px transparent;
		}

		&.active {
			font-weight: bold;
			background: $ico-collapse-daphne no-repeat scroll 0 50% / 24px 24px transparent;
			&:hover {
				color:$cerise_red;
				background: $ico-collapse-cerise no-repeat scroll 0 50% / 24px 24px transparent;
			}
		}
	}
}

.section-row {
	&.palette-highlight {
		.view-faqs {
			.card-qa-answer {
				color:white;
				td {
					color:$primary;
				}
			}
			.card-qa-question {
				color: $apple_red;
				background: $ico-expand-apple no-repeat scroll 0 50% / 24px 24px transparent;
				&:hover {
					color:white;
					background: $ico-expand-white no-repeat scroll 0 50% / 24px 24px transparent;
				}
				&.active {
					background: $ico-collapse-apple no-repeat scroll 0 50% / 24px 24px transparent;
					&:hover {
						background: $ico-collapse-white no-repeat scroll 0 50% / 24px 24px transparent;
					}
				}
			}
			a:hover {
				color:#D62F5F;
			}
		}
	}
}
